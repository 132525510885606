import { alchemyPay } from '@/assets'
import classNames from 'classnames'
import { Trans } from 'react-i18next'

const Power = ({ className }: { className?: string }) => {
  return (
    <div
      className={classNames(
        'my-[6px] flex items-center justify-center space-x-3 text-sm text-t3',
        className
      )}
    >
      <Trans
        i18nKey={'tg_wallet_ramp.trade_powered'}
        components={{
          highlight: <img className="h-5" src={alchemyPay} alt="" />,
          span: <span></span>
        }}
      ></Trans>
    </div>
  )
}

export default Power
