import { TToast } from '@/components/tmd'

export interface SendSuccessType {
  amount: string
  symbol: string
}

export interface SwapSuccessType {
  fromAmount: string
  fromSymbol: string
  toAmount: string
  toSymbol: string
}

export const sendSuccessToast = ({ amount, symbol }: SendSuccessType) => {
  TToast.success(`${amount} ${symbol} has been sent`)
}

export const sendFaildToast = ({ amount, symbol }: SendSuccessType) => {
  TToast.error(
    `Sending ${amount} ${symbol} failed. Please check the network or increase the gas fee and try again`
  )
}

export const swapSuccessToast = ({
  fromAmount,
  fromSymbol,
  toAmount,
  toSymbol
}: SwapSuccessType) => {
  TToast.success(
    `Successfully traded ${fromAmount} ${fromSymbol} for ${toAmount} ${toSymbol}`
  )
}

export const swapFaildToast = ({
  fromAmount,
  fromSymbol,
  toAmount,
  toSymbol
}: SwapSuccessType) => {
  TToast.error(
    `Failed to trade ${fromAmount} ${fromSymbol} for ${toAmount} ${toSymbol}. Please check the network or increase the gas fee and try again`
  )
}
