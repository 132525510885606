import { getOnrampOrderList, getOrderStatus } from '@/api/ramp'
import {
  HistoryItem,
  OnrampListItem,
  OnRampStatus,
  QuoteSide
} from '@/api/ramp/type'
import { alchemyPay, arrowSvgs } from '@/assets'
import { useTokenByName } from '@/hooks/api/useMarketApis'
import rampStore from '@/stores/rampStore'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import BigNumber from 'bignumber.js'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TokenImg from '../wallet/components/TokenImg'
import { Routers } from '@/router'
import { getStatusColor, getStatusIcon, getStatusStr } from './utils'
import { TIcon } from '@/components/tmd'
import useRampStore from '@/stores/rampStore/hooks/useRampStore'
import { TContainer } from '@/components/tmd'
import { getWalletTokenChainId } from '@/stores/tokenStore/util'
import dayjs from 'dayjs'
import useTokenStore from '@/stores/tokenStore/hooks/useTokenStore'
import chains, { onRampChain } from '@/proviers/web3Provider/chains'
import { LoadingIcon } from '@/components/Loading'
import Power from './components/Power'
import { t } from 'i18next'

const SCROLL_POSITION_KEY = 'historyPageScrollPosition'
const SCROLL_ITEM_KEY = 'historyItem'

export const HistoryPage: React.FC = () => {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true)
  const { historyList } = useRampStore()
  const listRef = useRef<HTMLDivElement>(null)

  const loadData = async () => {
    const result = await getOnrampOrderList({
      currentPage: 1,
      pageSize: 1000
    })
    const list = result.items
    rampStore.setHistoryList(list)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
    const time = setInterval(() => {
      loadData()
    }, 10000)
    const savedScrollPosition = sessionStorage.getItem(SCROLL_POSITION_KEY)
    if (savedScrollPosition && listRef.current) {
      listRef.current.scrollTop = parseInt(savedScrollPosition, 10)
    }

    return () => {
      clearInterval(time)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        sessionStorage.setItem(
          SCROLL_POSITION_KEY,
          listRef.current.scrollTop.toString()
        )
      }
    }

    const listElement = listRef.current
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const handleBack = () => {
    navigate(Routers.index)
  }

  const handleItemClick = (item: OnrampListItem) => {
    const scrollPosition = listRef.current?.scrollTop || 0
    sessionStorage.setItem(SCROLL_ITEM_KEY, JSON.stringify(item))
    navigate(Routers.ramp.paymentStatus(item.id.toString()), {
      state: { scrollPosition }
    })
  }

  return (
    <>
      <BackButton onClick={handleBack} />
      <TContainer className="flex flex-1 flex-col overflow-auto !px-0">
        <div className="flex flex-col justify-between">
          <div ref={listRef} className="h-full p-5">
            {historyList.length > 0 ? (
              historyList.map((item) => (
                <HItem
                  item={item}
                  key={item.orderNo}
                  onClick={() => handleItemClick(item)}
                />
              ))
            ) : isLoading ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <LoadingIcon isLoading={isLoading} />
              </div>
            ) : (
              <div className="flex h-[300px] items-center justify-center">
                No history
              </div>
            )}
          </div>
          {!isLoading ? <Power /> : null}
        </div>
      </TContainer>
    </>
  )
}

interface IHistoryItem {
  item: OnrampListItem
  onClick: () => void
}

const HItem: React.FC<IHistoryItem> = ({ item, onClick }) => {
  const { tokenList } = useTokenStore()

  const token = useMemo(() => {
    const chainId = Object.keys(onRampChain).find(
      (key) => onRampChain[key as any].chain === item.network
    )
    const find = tokenList.find(
      (tok) =>
        tok.chainId === Number(chainId) &&
        item.symbol?.toLowerCase() === tok.symbol?.toLowerCase()
    )
    return find
  }, [JSON.stringify(tokenList), item])

  const evmETHFlag = useMemo(() => {
    return token?.symbol.includes('ETH') && !token?.address
  }, [token?.symbol, token?.address])

  return (
    <div className=" mb-3 flex flex-col rounded-xl bg-bg3 p-3">
      <div
        className="mb-2 mt-1 flex justify-between border-b border-l1 pb-2"
        onClick={onClick}
      >
        <div className=" flex items-center gap-2 text-sm font-medium text-t1">
          <TokenImg
            symbol={token?.symbol ?? ''}
            image={evmETHFlag ? chains.ethereum.icon : token?.image}
            chainId={token?.chainId || -1}
            isNative={token?.isNative}
            symbolSize={24}
            chainSize={12}
          />
          {item.symbol}
        </div>
        <div className="flex items-center text-xs text-t3">
          {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          <TIcon name="tg_wallet_right-pointing-arrow" className="size-4" />
        </div>
      </div>
      <div className="flex justify-between py-1 text-xs text-t3">
        {t('tg_wallet_ramp_history.history_label1')}({item.fiat})
        <span className="font-medium text-t1">
          {item.totalFiatSize || item.fiatSize}
        </span>
      </div>

      <div className="flex justify-between py-1 text-xs text-t3">
        {t('tg_wallet_ramp_history.history_label2')}({item.fiat})
        <span className="font-medium text-t1">≈{item.fiatSize}</span>
      </div>

      <div className="flex justify-between py-1 text-xs text-t3">
        {t('tg_wallet_ramp_history.history_label3')}({item.symbol})
        <span className="font-medium text-t1">{item.amount}</span>
      </div>

      <div className="flex justify-between py-1 text-xs font-medium text-t1">
        {t('tg_wallet_ramp.trade_tab1')}
        <div className="flex items-center gap-1">
          <img src={getStatusIcon(item.status)} alt="" />
          <span className={getStatusColor(item.status)}>
            {getStatusStr(item.status)}
          </span>
        </div>
      </div>
    </div>
  )
}
