import { BackButton } from '@vkruglikov/react-telegram-web-app'
import usePageSetting from 'hooks/usePageSetting'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Swiper, SwiperRef, Tabs } from 'antd-mobile'
import { useEffect, useRef, useState } from 'react'
import BuyView from './components/BuyView'
import { SellView } from './components/SellView'
import {
  CurrencyItem,
  OnrampToken,
  QuoteSide,
  SupportCryptoItem
} from '@/api/ramp/type'
import { observer } from 'mobx-react-lite'
import rampStore from '@/stores/rampStore'
import { useQuery } from '@tanstack/react-query'
import { getOnrampSymbolList, getSupportedCryptoList } from '@/api/ramp'
import { onRampChain } from '@/proviers/web3Provider/chains'
import { useUnsupportPopup } from './components/UnsupportPopup'
import { Routers } from '@/router'
import { TIcon, TIconButton, TContainer } from '@/components/tmd'
import IText from '@/components/IText'

const tabs = [
  {
    title: <IText value="tg_wallet_ramp.trade_tab1" />,
    children: <BuyView />,
    type: QuoteSide.BUY
  }
  // {
  //   title: 'Sell crypto',
  //   children: <SellView />,
  //   type: QuoteSide.SELL
  // }
]
const TradeView = observer(() => {
  const navigate = useNavigate()
  const ref = useRef<SwiperRef>(null)
  const [tabKey, setTabKey] = useState(
    tabs.findIndex((tab) => tab.type === rampStore.selectedTab)
  )
  const selectedToken = rampStore.selectedToken
  const { data: cryptoList } = useQuery({
    queryKey: ['getSupportedCryptoList'],
    queryFn: () => {
      return getOnrampSymbolList()
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

  const { setUnsupportPopupOpen, unsupportPopup } = useUnsupportPopup()

  useEffect(() => {
    if (!cryptoList) return
    rampStore.setSupportCryptoList(cryptoList)
    const isAlchemySupported = cryptoList?.find(
      (item) =>
        item.symbol.toLowerCase() ===
          rampStore.selectedToken?.symbol?.toLowerCase() &&
        item.network === onRampChain[rampStore.selectedToken?.chainId].chain &&
        item.buy
    )

    rampStore.setIsAlchemySupported(!!isAlchemySupported)
    if (!isAlchemySupported && rampStore.selectedToken) {
      setUnsupportPopupOpen(true)
    } else {
      const cryptoDetail: OnrampToken | undefined =
        rampStore.supportCryptoList.find(
          (item) =>
            item.symbol === selectedToken.symbol &&
            item.network ===
              onRampChain[rampStore.selectedToken?.chainId].chain &&
            item.buy
        )
      if (tabKey === 0) {
        rampStore.setBuySelectedCurrency({
          ...rampStore.buySelectedCurrency,
          cryptoDetail
        })
      } else {
        // rampStore.setSellSelectedCurrency({ ...selectedCurrency, cryptoDetail })
      }
      setUnsupportPopupOpen(false)
    }
  }, [cryptoList, setUnsupportPopupOpen, tabKey, selectedToken.symbol])

  usePageSetting({
    tabBar: {
      show: false
    }
  })
  const handleBack = () => {
    navigate(-1)
  }

  return (
    <>
      {unsupportPopup}
      <BackButton onClick={handleBack} />
      <TContainer className="flex flex-1 flex-col">
        <div className="sticky top-0 z-10 flex shrink-0 items-center justify-between py-1">
          <Tabs
            activeKey={tabKey.toString()}
            onChange={(key) => {
              setTabKey(Number(key))
              // rampStore.setSelectedTab(tabs[Number(key)].type)
              ref?.current?.swipeTo(Number(key))
            }}
            style={{
              '--active-line-color': 'var(--text-t1)',
              '--active-title-color': 'var(--text-t1)'
            }}
            className="[&_.adm-tabs-tab]:pt-2px h-[52px]  !shrink-0 text-t3  [&_.adm-tabs-header]:h-[52px] [&_.adm-tabs-header]:border-none [&_.adm-tabs-tab-line]:hidden [&_.adm-tabs-tab-wrapper]:flex-none [&_.adm-tabs-tab-wrapper]:px-0 [&_.adm-tabs-tab.adm-tabs-tab-active]:font-semibold [&_.adm-tabs-tab.adm-tabs-tab-active]:opacity-100 [&_.adm-tabs-tab]:py-[11px] [&_.adm-tabs-tab]:text-[20px] [&_.adm-tabs-tab]:transition-transform"
          >
            {tabs.map((tab, index) => (
              <Tabs.Tab
                key={index}
                title={tab.title}
                className="mr-[16px] h-[52px]"
              />
            ))}
          </Tabs>
          <TIconButton
            className="size-9"
            onClick={() => navigate(Routers.ramp.history)}
          >
            <TIcon name="tg_wallet_order" fontSize="20" className="text-t1" />
          </TIconButton>
        </div>
        <Swiper
          allowTouchMove={false}
          className="flex-1 outline-0"
          ref={ref}
          indicator={() => null}
          tabIndex={tabKey}
          defaultIndex={tabKey}
          onIndexChange={(index) => setTabKey(index)}
        >
          {tabs.map((tab, index) => (
            <Swiper.Item
              key={index}
              className="no-scrollbar max-w-full overflow-y-auto overflow-x-hidden bg-bg1"
            >
              {tab.children}
            </Swiper.Item>
          ))}
        </Swiper>
      </TContainer>
    </>
  )
})

export default TradeView
