/* eslint-disable @typescript-eslint/ban-ts-comment */
import { alchemyPay, mastercardSvg } from '@/assets'
import Countdown from './components/Countdown'
import Order from './components/Order'
import rampStore from '@/stores/rampStore'
import { SupportPayWay } from '@/api/ramp/type'
import { useMutation } from '@tanstack/react-query'
import { createOnrampOrder } from '@/api/ramp'
import { onRampChain } from '@/proviers/web3Provider/chains'
import useLoginInfo from '@/hooks/useLoginInfo'
import { useMemo, useState } from 'react'
import {
  TConfirmButton,
  TContainer,
  TIcon,
  TLoadingButton
} from '@/components/tmd'
// import { toast } from 'react-toastify'
import toast from '@/components/tmd/toast/Toast'
import { useNavigate, useParams } from 'react-router-dom'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { getCookieQuery } from './utils'
import useRampStore from '@/stores/rampStore/hooks/useRampStore'
import { Toast } from '@/components/tmd'
import { t } from 'i18next'
import Power from './components/Power'

export const ConfirmPayment: React.FC = () => {
  const { lastFourDigits, cardType } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const type = rampStore.selectedTab
  const amount = rampStore.buyAmount
  const getAddressByToken = useLoginInfo().getAddressByToken
  const clearAmount = rampStore.clearAmount

  const { buyQuotes } = useRampStore()

  const cardQuote = useMemo(() => {
    return buyQuotes.find((i) => i.code === SupportPayWay.CREDITCARD)
  }, [buyQuotes])
  const { mutate, isPending } = useMutation({
    mutationFn: createOnrampOrder,
    onSuccess: (data: any) => {
      Toast.clear()
      setLoading(false)
      if (!data?.data) {
        toast.error(data?.msg)
        return
      }
      clearAmount()
      if (data.data.payUrl) {
        window.location.href = data.data.payUrl
      }
    },
    onError: (e) => {
      Toast.clear()
      toast.error(e.message || t('tg_wallet_ramp_payment_confirm.confirm_err1'))
    }
  })

  const onConfirm = async () => {
    setLoading(true)
    Toast.show({
      icon: 'loading',
      content: '',
      duration: 0,
      maskClickable: false
    })
    let cookieQuery = {
      cookie: '',
      channalCookie: '',
      clientIp: ''
    }
    try {
      cookieQuery = await getCookieQuery()
    } catch (e) {
      setLoading(false)
      Toast.clear()
      toast.error(t('tg_wallet_ramp_payment_confirm.confirm_err2'))
      return
    }

    if (!cookieQuery.channalCookie || !cookieQuery.cookie) {
      setLoading(false)
      Toast.clear()
      toast.error(t('tg_wallet_ramp_payment_confirm.confirm_err3'))
      return
    }
    const extendParams = {
      cookie: cookieQuery.cookie,
      channelCookie: cookieQuery.channalCookie
    }
    const clientIp = cookieQuery.clientIp
    const params = {
      clientOid: '',
      memo: '',
      orderTime: '',
      userPaymentId: cardType || '',
      address: getAddressByToken(rampStore.selectedToken) || '',
      code: SupportPayWay.CREDITCARD,
      depositType: '4',
      fiat: rampStore.buySelectedCurrency.symbol,
      network: onRampChain[rampStore.selectedToken?.chainId].chain,
      side: '0',
      size: rampStore.buyAmount,
      amount: cardQuote?.size || '',
      symbol: rampStore.selectedToken.symbol,
      channelCookie: extendParams.channelCookie,
      cookie: extendParams.cookie,
      clientIp
    }
    mutate(params)
  }
  return (
    <>
      {!loading && <BackButton onClick={() => navigate(-1)} />}
      <TContainer className="flex flex-1 flex-col">
        <div className="flex h-full flex-col justify-between">
          <div>
            <div className="flex h-[52px] items-center">
              <span className="text-xl font-semibold text-t1">
                {t('tg_wallet_ramp_payment_confirm.confirm_title')}
              </span>
            </div>
            <div className="flex flex-col pt-1">
              <div className="text-base font-medium text-t1">
                {t('tg_wallet_ramp_payment_confirm.confirm_subtitle')}
              </div>
              <div className="mt-3 rounded-lg bg-bg3 px-4 py-[15px]">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-t3 ">
                    {t('tg_wallet_ramp_payment_confirm.confirm_chosen')}
                  </span>
                  <span className="text-base font-medium text-blue">
                    {'Card'}
                  </span>
                </div>
                <div className=" my-3 border-b border-l1"></div>
                <div className="flex items-center gap-[10px]">
                  <TIcon
                    name="tg_wallet_Card"
                    fontSize="24"
                    className="text-t2"
                  />
                  <span className="text-center text-sm text-t3">
                    **** {lastFourDigits}
                  </span>
                </div>
              </div>
              {cardQuote && (
                <Countdown
                  start={!!cardQuote}
                  // reloadFun={loadQuote}
                  amount={amount}
                />
              )}
              {cardQuote && (
                <div className="mt-[-14px]">
                  <Order type={type} data={cardQuote} amount={amount} />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[6px]">
            {loading ? (
              <TLoadingButton disabled={true} />
            ) : (
              <TConfirmButton
                disabled={loading}
                onConfirm={onConfirm}
                text={t('tg_wallet_ramp_payment_confirm.confirm_btn')}
              />
            )}
            <Power />
          </div>
        </div>
      </TContainer>
    </>
  )
}
