import { singDogeTx } from '@/api'
import { ApiParams, DogeTxRes } from '@/api/type'
import { DexTransaction } from '@/constants/types'
import { sendRawTransactionByCenterApi } from '@/hooks/api/chain'
import { UserType } from '@/stores/userStore/type'
import axios from 'axios'

export const dogeScanUrl = 'https://dogechain.info/tx/'
export const dogeScanAddressUrl = 'https://dogechain.info/address/'
export const mockDogeChainId = 3
const rpcUrl: string = 'https://api.bitcore.io/api/DOGE/mainnet'
const sendTxUrl = rpcUrl + '/tx/send'
const txSize = 1 * 148 + 2 * 34 + 10

const api = axios.create({
  baseURL: rpcUrl
})

/** get doge balance */
export const getDogeBalabceByAddress = async (address: string) => {
  const res = await api.get(`/address/${address}/balance`)
  const resBalance = res.data
  if (resBalance.balance === 0) {
    return null
  } else {
    return {
      balance: resBalance.balance,
      formatted: resBalance.balance / 1e8
    }
  }
}

export const pushDogeTx = async ({
  rawTx,
  apiParams
}: {
  rawTx: string
  apiParams?: ApiParams
}) => {
  if (apiParams) {
    const hash = await sendRawTransactionByCenterApi({
      apiParams,
      callData: rawTx,
      tx: ''
    })
    if (hash) return hash
  }

  const res = await sendDogeTx(rawTx)

  return res.txid
}

/** send tx */
export const sendDogeTx = async (rawTx: string) => {
  const res = await api.post(sendTxUrl, { rawTx })
  return res.data
}

/** Get Fee estimate for within N blocks */
export const getDogeFeeByBlock = async (address?: string, n = 22) => {
  const res = await api.get(`/fee/${n}`)
  return (res.data?.feerate || 0.01) * txSize
}

/** sing tx */
export const signDogeTransaction = async (
  params: Omit<DogeTxRes, 'rpcUrl'>
) => {
  return await singDogeTx({ ...params, rpcUrl })
}

/** get tx status */
export const getDogeTransactionStatus = async (txid: string) => {
  const res = await api.get(`/tx/${txid}`)
  return res.data
}
