import TonWeb from 'tonweb'
import { AddressType } from 'tonweb/dist/types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import tonUtils from 'tonweb/src/utils/Utils'
import axios from 'axios'
import { getHttpEndpoint } from '@orbs-network/ton-access'
import {
  Address,
  JettonMaster,
  JettonWallet,
  TonClient,
  fromNano,
  SendMode,
  Cell
} from '@ton/ton'
import { TonTxRequestStandard } from '@/utils/tgSdkJavascript/ton/types'
import { UserType } from '@/stores/userStore/type'
import { DexTransaction } from '@/constants/types'
import {
  sendRawTransactionByCenterApi,
  SendTransactionByCenterParamsType
} from '@/hooks/api/chain'
import { getChainByChainId } from '@/stores/walletStore/utils'
import { ApiParams, CenterSubmitResult } from '@/api/type'
import { errorContents } from './const'

export type TonTestnetSigningTransactionType = {
  fromAddress: string
  publicKey: string
  amount: string
  toAddress: string
  memo: string | Uint8Array | Cell
  tokenContractAddress?: string
  tokenPrecision?: number
  targetAddress?: string
}

export type TonTestnetSendTransactionParams = {
  data: string
  from: string
  gas: string
  gasPrice?: string
  maxPriorityFeePerGas?: string
  minReceiveAmount: string
  signatureData: string[]
  to: string
  value: string
  decimals: number
}

export const mockTonTestnetChainId = 1101
export const mockTonOkxChainID = 607
export const tonDecimals = 9
export const tonSymbol = 'TON'
export const minTonBalance = 0.05

export const apiKeyTestnet: string =
  '227f87a614319d10e4c20f0f485cd101eca12989bf59da0e33b3c3da8ad534b2'
export const tonRpcTestnet: string =
  'https://testnet.toncenter.com/api/v2/jsonRPC'
// https://testnet.toncenter.com/api/v2/jsonRPC
// https://rpc-testnet.tonsquare.io
// https://testnet.toncenter.com/
const hashHttpTestnet = 'https://testnet.toncenter.com/api/index/v1'
const hashHttpTestnetV3 = 'https://testnet.toncenter.com/api/v3'
export const tonScanTestnetUrl = 'https://testnet.tonviewer.com/transaction/'
export const tonScanAddressTestnetUrl = 'https://testnet.tonviewer.com/'

export async function getClient() {
  return new TonClient({
    endpoint: await getHttpEndpoint({
      network: 'testnet'
    })
  })
}

export async function getTonWebTestnetAsync() {
  const { default: TonWeb } = await import('tonweb')
  return TonWeb
}

export async function getTonWebTestnetProvider() {
  const TonWeb = await getTonWebTestnetAsync()
  return new TonWeb(
    new TonWeb.HttpProvider(tonRpcTestnet, { apiKey: apiKeyTestnet })
  )
}

/** no reference */
export const getTonTestnetBalance = async ({
  tonAddress,
  tokenContractAddress,
  tokenPrecision = tonDecimals
}: {
  tonAddress: string
  tokenContractAddress?: AddressType
  tokenPrecision?: number
}) => {
  // get the decentralized RPC endpoint
  const tonWeb = await getTonWebTestnetProvider()
  const wallet = tonWeb.wallet.create({ address: tonAddress }) // if your know only address at this moment
  // const wallet = tonWeb.wallet.create({publicKey: TonWeb.utils.hexToBytes(publicKey)});

  const address = await wallet.getAddress()
  if (tokenContractAddress) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const jettonMinter = new TonWeb.token.jetton.JettonMinter(tonWeb.provider, {
      address: tokenContractAddress
    })
    const jettonWalletAddress =
      await jettonMinter.getJettonWalletAddress(address)
    // console.log('My jetton wallet for ' + name + ' is ' + jettonWalletAddress.toString(true, true, true));
    const jettonWallet = new (
      await getTonWebTestnetAsync()
    ).token.jetton.JettonWallet(tonWeb.provider, {
      address: jettonWalletAddress
    })
    const balance = (await jettonWallet.getData()).balance
    return {
      balance,
      formatted: Number(balance.toString()) / 10 ** tokenPrecision
    }
  }

  const balance = await tonWeb.getBalance(address)

  return {
    balance,
    formatted: (await getTonWebTestnetAsync()).utils.fromNano(balance)
  }
}

export const getTokenTestnetBalance = async ({
  tonAddress,
  tokenAddress
}: {
  tokenAddress: string
  tonAddress: string
}) => {
  try {
    const client = await getClient()
    const token = Address.parse(tokenAddress)
    const walletAddress = Address.parse(tonAddress || '')
    const jettonMaster = client.open(JettonMaster.create(token))
    const jettonWalletAddress =
      await jettonMaster.getWalletAddress(walletAddress)

    const jettonWallet = client.open(JettonWallet.create(jettonWalletAddress))

    const balance = await jettonWallet.getBalance()

    // const data = await jettonMaster.getJettonData()
    // const buffer = data.content.toBoc({ idx: false })

    return { value: balance }
  } catch (error) {
    console.error('Error getting token balance and decimals:', error)
    throw error
  }
}

/**
 * build transfer signing message
 * @param transactionInfo  {publicKey: "be91c0566bed6a186780b5711529b0652e505bb3f9fc92866fb0c0f400c98dd6",amount: "0.3",toAddress: "EQC4d8D4ERsT6DH0Xzz_Ey8Yja2wXVnUtaffVOtm1htS6qG1",memo: "1111"}
 * @returns
 */
export async function createSigningTestnetTransaction(
  transactionInfo: TonTestnetSigningTransactionType
) {
  try {
    const tonWeb = await getTonWebTestnetProvider()
    const WalletClass = tonWeb.wallet.all['v4R2']
    const wallet = new WalletClass(tonWeb.provider, {
      publicKey: tonWeb.utils.hexToBytes(transactionInfo.publicKey),
      wc: 0
    })
    const seqno = (await wallet.methods.seqno().call()) || 0
    let stateInit = null
    if (seqno == 0) {
      const deploy = await wallet.createStateInit()
      stateInit = deploy.stateInit
    }

    let signingMessage
    let stateInitBoc
    let sendmode = SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS // 3
    const balance = await getTonTestnetBalance({
      tonAddress: transactionInfo.fromAddress
    })
    if (
      transactionInfo.tokenContractAddress &&
      transactionInfo.tokenContractAddress.trim().length > 0 &&
      transactionInfo.tokenPrecision
    ) {
      if (Number(balance.formatted) < minTonBalance) {
        throw new Error(`Ton balance least ${minTonBalance}`)
      }
      const jettonMinter = new (
        await getTonWebTestnetAsync()
      ).token.jetton.JettonMinter(
        tonWeb.provider,
        // @ts-ignore
        { address: transactionInfo.tokenContractAddress }
      )
      //
      const walletAddress = await wallet.getAddress()
      const jettonWalletAddress =
        await jettonMinter.getJettonWalletAddress(walletAddress)
      //
      const jettonWallet = new (
        await getTonWebTestnetAsync()
      ).token.jetton.JettonWallet(tonWeb.provider, {
        address: jettonWalletAddress.toString(true, true, false)
      })

      let comment
      if (typeof transactionInfo.memo === 'string') {
        comment = new Uint8Array([
          ...new Uint8Array(4),
          ...new TextEncoder().encode(transactionInfo.memo || '')
        ])
      } else {
        comment = transactionInfo.memo
      }

      //
      const tokenAmount =
        Number(transactionInfo.amount) * 10 ** transactionInfo.tokenPrecision
      const convertedAmount = (await getTonWebTestnetAsync()).utils.toNano(
        (tokenAmount / 10 ** 9).toString()
      )
      //
      const transferBody = await jettonWallet.createTransferBody({
        queryId: seqno,
        // @ts-ignore
        jettonAmount: convertedAmount,
        toAddress: new (await getTonWebTestnetAsync()).utils.Address(
          transactionInfo.toAddress
        ),
        forwardPayload: comment,
        forwardAmount: (await getTonWebTestnetAsync()).utils.toNano('0.0001'),
        responseAddress: walletAddress
      })
      //
      const externalMessage = await wallet.createTransferMessage(
        new Uint8Array(), //
        jettonWalletAddress.toString(true, true, false),
        (await getTonWebTestnetAsync()).utils.toNano('0.05'), //
        seqno,
        transferBody,
        sendmode, //3, // sendmode
        true, //
        stateInit as any
      )

      const cellBase64 = await externalMessage.signingMessage.toBoc(false)

      if (stateInit) {
        const cellBase641 = await stateInit.toBoc(false)
        stateInitBoc = tonWeb.utils.bytesToHex(cellBase641)
      }
      signingMessage = tonWeb.utils.bytesToHex(cellBase64)

      return {
        signingMessageBoc: signingMessage,
        stateInitBoc
      }
    } else {
      if (Number(balance.formatted) - Number(transactionInfo.amount) <= 0.01) {
        sendmode = SendMode.CARRY_ALL_REMAINING_BALANCE // 128
      }
      let toAddress: AddressType = new (
        await getTonWebTestnetAsync()
      ).utils.Address(transactionInfo.toAddress).toString(true, true, true)
      const info = await tonWeb.provider.getAddressInfo(
        transactionInfo.toAddress
      )
      if (info.state !== 'active') {
        toAddress = new (await getTonWebTestnetAsync()).utils.Address(
          transactionInfo.toAddress
        ).toString(true, true, false) // convert to non-bounce
      }
      const externalMessage = await wallet.createTransferMessage(
        new Uint8Array(),
        toAddress,
        (await getTonWebTestnetAsync()).utils.toNano(transactionInfo.amount),
        seqno,
        transactionInfo.memo, //
        sendmode, // 3,
        true,
        stateInit as any
      )
      const cellBase64 = await externalMessage.signingMessage.toBoc(false)
      signingMessage = tonWeb.utils.bytesToHex(cellBase64)
      // const hash = await externalMessage.signingMessage.hash()
      if (stateInit) {
        const cellBase641 = await stateInit.toBoc(false)
        stateInitBoc = tonWeb.utils.bytesToHex(cellBase641)
      }
    }
    return {
      signingMessageBoc: signingMessage,
      stateInitBoc
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

// export async function createSigningTransactionData(
//   data: TonTestnetSendTransactionParams & {
//     publicKey: string
//   }
// ) {
//   try {
//     const tonWeb = await getTonWebProvider()
//     const WalletClass = tonWeb.wallet.all['v4R2']
//     const wallet = new WalletClass(tonWeb.provider, {
//       publicKey: tonWeb.utils.hexToBytes(data.publicKey),
//       wc: 0
//     })
//     const seqno = (await wallet.methods.seqno().call()) || 0
//     let stateInit = null
//     if (seqno == 0) {
//       const deploy = await wallet.createStateInit()
//       stateInit = deploy.stateInit
//     }

//     const signingMessage = data.data

//     let stateInitBoc

//     if (stateInit) {
//       const cellBase641 = await stateInit.toBoc(false)
//       stateInitBoc = tonWeb.utils.bytesToHex(cellBase641)
//     }

//     return {
//       signingMessageBoc: signingMessage,
//       stateInitBoc: stateInitBoc ? stateInitBoc : ''
//     }
//   } catch (e) {
//     console.error(e)
//   }
// }

export const pushTonTestnetTx = async ({
  signedTransaction,
  apiParams
}: {
  signedTransaction: string
  apiParams?: ApiParams
}) => {
  if (apiParams) {
    const hash = await sendRawTransactionByCenterApi({
      apiParams,
      callData: signedTransaction,
      tx: ''
    })
    if (hash) return hash
  }

  const tranRes = await sendTestnetTransaction(signedTransaction)
  if (tranRes && tranRes['@type'] == 'ok') {
    return tranRes.msgHash as string
  } else {
    throw new Error(errorContents.transactionError)
  }
}

export async function sendTestnetTransaction(signedTransaction: string) {
  const tonWeb = await getTonWebTestnetProvider()

  const cell = tonWeb.boc.Cell.fromBoc(
    tonWeb.utils.base64ToBytes(signedTransaction)
  )[0]
  const msgHash = tonWeb.utils.bytesToBase64(await cell.hash())

  const result = await tonWeb.provider.sendBoc(signedTransaction)
  return { ...result, msgHash }
}

// {@extra: "1723608477.1462789:0:0.6971379973574183", @type: "query.fees", destination_fees: [], source_fees: {@type: "fees",fwd_fee: 0,gas_fee: 0,in_fwd_fee: 1006800,storage_fee:618}}
export async function sendTestnetMessageFee(
  address: string,
  signedTransaction: string
) {
  const tonWeb = await getTonWebTestnetProvider()
  return tonWeb.provider.getEstimateFee({
    address: address,
    body: signedTransaction
  })
}

export async function getTestnetTransactionsByInMessageHash(msg_hash: string) {
  const transRes = await axios.get(
    `${hashHttpTestnet}/getTransactionsByInMessageHash?msg_hash=${encodeURIComponent(
      msg_hash
    )}&include_msg_body=false&include_block=false`,
    {
      headers: {
        'X-API-Key': apiKeyTestnet
      }
    }
  )
  return transRes?.data || []
}

export async function getTestnetTransactionsByInMessageHashV2(
  msg_hash: string
) {
  try {
    const transRes = await axios.get(
      `${hashHttpTestnetV3}/transactionsByMessage?msg_hash=${encodeURIComponent(
        msg_hash
      )}&direction=in&limit=10&offset=0`,
      {
        headers: {
          'X-API-Key': apiKeyTestnet
        }
      }
    )
    console.log({
      key: 'tonTest',
      transRes
    })
    return transRes?.data || []
  } catch (error) {
    console.warn('transactionsByMessage', error)
    return null
  }
}

// use the v2 api
export async function getTestnetTransactions(
  address: string,
  limit: number = 10
) {
  const tonweb = await getTonWebTestnetProvider()

  return await tonweb.getTransactions(address, limit)
}

export function convertBase64ToHex(base64: string) {
  return tonUtils.bytesToHex(tonUtils.base64ToBytes(base64))
}

export async function createSigningTransactionPureTestnet(
  transactionInfo: TonTxRequestStandard,
  valid_until?: number | undefined
) {
  try {
    const tonWeb = await getTonWebTestnetProvider()
    const WalletClass = tonWeb.wallet.all['v4R2']
    const wallet = new WalletClass(tonWeb.provider, {
      publicKey: tonWeb.utils.hexToBytes(transactionInfo.publicKey as string),
      wc: 0
    })
    // console.log('tonWallet ==>', window.tonWeb = tonWeb, window.tonWallet = wallet)
    const seqno = (await wallet.methods.seqno().call()) || 0
    let stateInit = null
    if (seqno == 0) {
      const deploy = await wallet.createStateInit()
      stateInit = deploy.stateInit
    }

    // let signingMessage
    let stateInitBoc
    const balance = await getTonTestnetBalance({
      tonAddress: transactionInfo.fromAddress
    })
    const msgsRaw = transactionInfo.body.messages
    const msgsDataPromiseList = msgsRaw.map(async (item) => {
      let sendMode = SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS
      const transferTonAmount = item.amount
      if (
        Number(balance.formatted) - Number(fromNano(transferTonAmount)) <=
        0.01
      ) {
        sendMode = SendMode.CARRY_ALL_REMAINING_BALANCE //128
      }

      let toAddress: AddressType = new tonWeb.utils.Address(
        item.address
      ).toString(true, true, true)
      const info = await tonWeb.provider.getAddressInfo(toAddress)

      if (info.state !== 'active') {
        toAddress = new tonWeb.utils.Address(toAddress).toString(
          true,
          true,
          false
        ) // convert to non-bounce
      }

      return {
        toAddress,
        sendMode,
        amount: transferTonAmount,
        payload: item.payload
          ? tonWeb.boc.Cell.oneFromBoc(
              new Uint8Array(Buffer.from(item.payload, 'base64'))
            )
          : '',
        stateInit: item.stateInit
          ? tonWeb.boc.Cell.oneFromBoc(
              new Uint8Array(Buffer.from(item.stateInit, 'base64'))
            )
          : ''
      }
    })

    const msgsData = await Promise.all(msgsDataPromiseList)

    const defaultUntil = Math.floor(Date.now() / 1000) + 5 * 60
    const validUntil =
      valid_until && +valid_until
        ? Math.min(+valid_until, defaultUntil)
        : defaultUntil

    // console.log('valid_until', {
    //   valid_until,
    //   defaultUntil,
    //   validUntil
    // })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const externalMessage = await wallet.createTransferMessages(
      new Uint8Array(),
      seqno,
      msgsData,
      true,
      validUntil
    )
    const cellBase64 = await externalMessage.signingMessage.toBoc(false)
    const signingMessage = tonWeb.utils.bytesToHex(cellBase64)
    if (stateInit) {
      const cellBase641 = await stateInit.toBoc(false)
      stateInitBoc = tonWeb.utils.bytesToHex(cellBase641)
    }
    return {
      signingMessageBoc: signingMessage,
      stateInitBoc
    }
  } catch (e) {
    console.error(e)
    return {
      signingMessageBoc: '',
      stateInitBoc: ''
    }
  }
}
