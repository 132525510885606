import { OnRampStatus } from '@/api/ramp/type'
import {
  closedIcon,
  completedIcon,
  failedIcon,
  processIcon,
  rampLoading
} from '@/assets'
import {
  GB,
  US,
  EU,
  ZA,
  DK,
  HU,
  PL,
  CH,
  SE,
  NO,
  AU,
  CA,
  HK,
  JP,
  SG,
  NZ,
  CL,
  ID,
  MY,
  TW,
  KR,
  AE,
  RO,
  MD,
  IL,
  HR,
  IN,
  CO,
  PH,
  MX,
  BR,
  VN,
  TH,
  PE,
  NG,
  BG,
  KZ,
  TR,
  AR
} from 'country-flag-icons/react/3x2'

export const getStatusColor = (status?: number) => {
  switch (status) {
    case 1:
    case 2:
      return 'text-blue'
    case 3:
      return 'text-green'
    case 4:
      return 'text-red'
    // case OnRampStatus.CANCEL:
    //   return 'text-yellow'
    // case OnRampStatus.PAY_SUCCESS:
    //   return 'text-blue'
    // case OnRampStatus.TRANSFER:
    //   return 'text-blue'
    // case OnRampStatus.INVALID_ADDRESS:
    //   return 'text-red'
    // case OnRampStatus.REFUNDED:
    //   return 'text-red'
    // case OnRampStatus.RISK_CONTROL:
    //   return 'text-red'
    default:
      return 'text-yellow'
  }
}

export const getStatusIcon = (status?: number) => {
  switch (status) {
    case 1:
    case 2:
      return processIcon
    case 3:
      return completedIcon
    case 4:
      return failedIcon
    default:
      return closedIcon
  }
}

export const getStatusStr = (status?: number) => {
  switch (status) {
    case 1:
    case 2:
      return 'Processing'
    case 3:
      return 'Completed'
    case 4:
      return 'Failed'
    default:
      return 'Closed'
  }
}

export const getPaymentStatus = (status?: number) => {
  switch (status) {
    case 1:
    case 2:
      return 'Order Created'
    case 3:
      return 'Pay success' // t('tg_wallet_ramp_payment_status.payment_success')
    case 4:
      return 'Pay fail' // t('tg_wallet_ramp_payment_status.payment_fail')
    default:
      return 'Closed' // t('tg_wallet_ramp_payment_status.payment_close')
  }
}

export const getPaymentStatusIcon = (status?: number) => {
  switch (status) {
    case 1:
    case 2:
      return rampLoading
    case 3:
      return completedIcon
    case 4:
      return failedIcon
    default:
      return closedIcon
  }
}

export const getCountryIcon = (Country: string) => {
  switch (Country) {
    case 'US':
      return US
    case 'DE':
      return EU
    case 'ZA':
      return ZA
    case 'DK':
      return DK
    case 'HU':
      return HU
    case 'GB':
      return GB
    case 'PL':
      return PL
    case 'CH':
      return CH
    case 'SE':
      return SE
    case 'NO':
      return NO
    case 'AU':
      return AU
    case 'CA':
      return CA
    case 'HK':
      return HK
    case 'JP':
      return JP
    case 'SG':
      return SG
    case 'NZ':
      return NZ
    case 'CL':
      return CL
    case 'AR':
      return AR
    case 'TR':
      return TR
    case 'KZ':
      return KZ
    case 'BG':
      return BG
    case 'NG':
      return NG
    case 'ID':
      return ID
    case 'PE':
      return PE
    case 'MY':
      return MY
    case 'TH':
      return TH
    case 'VN':
      return VN
    case 'BR':
      return BR
    case 'MX':
      return MX
    case 'PH':
      return PH
    case 'CO':
      return CO
    case 'IN':
      return IN
    case 'HR':
      return HR
    case 'IL':
      return IL
    case 'MD':
      return MD
    case 'RO':
      return RO
    case 'AE':
      return AE
    case 'KR':
      return KR
    case 'TW':
      return TW
    default:
      return US
  }
}

export const getCookieQuery = (): Promise<{
  cookie: string
  channalCookie: string
  clientIp: string
}> => {
  return new Promise((resolve, reject) => {
    const timerCookie = setTimeout(() => {
      clearTimeout(timerCookie)
      reject('')
    }, 1000 * 15)
    setTimeout(async () => {
      const xmlhttp = new XMLHttpRequest()
      xmlhttp.open('get', 'https://api.ipify.org?format=json', false)
      xmlhttp.send()
      const ipRes = JSON.parse(xmlhttp.responseText)
      //@ts-ignore
      const channalCookie = await window?.ACHRampRiskSDK.getChannelCookie()
      //@ts-ignore
      const cookie = await window?.ACHRampRiskSDK.getCookie()
      resolve({
        cookie,
        channalCookie,
        clientIp: ipRes.ip
      })
    })
  })
}
