import {
  toast as rToast,
  cssTransition as rCssTransition,
  ToastOptions
} from 'react-toastify'
import React from 'react'
import { TIcon as Icon } from '../icon'

// export const defaultToastOptions: { [key: string]: any } = {
export const defaultToastOptions: ToastOptions = {
  position: 'top-right',
  hideProgressBar: true,
  autoClose: 3000,
  pauseOnHover: false,
  closeButton: false,
  className: 'tmd-toast',
  transition: rCssTransition({
    enter: 'tmd-toast-animated__enter',
    exit: 'tmd-toast-animated__leave'
  })
}
const toast = (content: React.ReactNode, options?: ToastOptions) => {
  return rToast(content, {
    ...defaultToastOptions,
    ...options
  })
}

const ToastContentWrap = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>
}

toast.success = (
  content: React.ReactNode,
  options?: { [key: string]: any }
) => {
  return rToast.success(<ToastContentWrap>{content}</ToastContentWrap>, {
    icon: (
      <Icon
        name="tg_wallet_finalize-facetiousness"
        fontSize="20"
        className="text-green"
      />
    ),
    ...defaultToastOptions,
    ...options
  })
}

toast.dismiss = rToast.dismiss

toast.error = (content: React.ReactNode, options?: { [key: string]: any }) => {
  return rToast.error(<ToastContentWrap>{content}</ToastContentWrap>, {
    icon: (
      <Icon
        name="tg_wallet_fail-facetiousness"
        fontSize="20"
        className="text-red"
      />
    ),
    ...defaultToastOptions,
    ...options
  })
}

toast.info = (content: React.ReactNode, options?: { [key: string]: any }) => {
  return rToast.info(<ToastContentWrap>{content}</ToastContentWrap>, {
    icon: (
      <Icon
        name="tg_wallet_present-facetiousness"
        fontSize="20"
        className="text-orange"
      />
    ),
    ...defaultToastOptions,
    ...options
  })
}

toast.warn = (content: React.ReactNode, options?: { [key: string]: any }) => {
  return rToast.warn(<ToastContentWrap>{content}</ToastContentWrap>, {
    icon: (
      <Icon
        name="tg_wallet_present-facetiousness"
        fontSize="20"
        className="text-orange"
      />
    ),
    ...defaultToastOptions,
    ...options
  })
}

toast.loading = (
  content: React.ReactNode,
  options?: { [key: string]: any }
) => {
  return rToast.loading(<ToastContentWrap>{content}</ToastContentWrap>, {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          d="M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 9.01508 2.69399 8.03981 3.0709 7.12987C3.44782 6.21993 4.00026 5.39314 4.6967 4.6967C5.39314 4.00026 6.21994 3.44781 7.12988 3.0709C8.03982 2.69399 9.01509 2.5 10 2.5C10.9849 2.5 11.9602 2.69399 12.8701 3.07091C13.7801 3.44782 14.6069 4.00026 15.3033 4.6967C15.9997 5.39314 16.5522 6.21994 16.9291 7.12988C17.306 8.03982 17.5 9.01509 17.5 10L17.5 10Z"
          stroke="var(--background-l1)"
          strokeWidth="2.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 2.5C10.9849 2.5 11.9602 2.69399 12.8701 3.0709C13.7801 3.44781 14.6069 4.00026 15.3033 4.6967C15.9997 5.39314 16.5522 6.21993 16.9291 7.12988C17.306 8.03982 17.5 9.01509 17.5 10"
          stroke="var(--text-t1)"
          strokeWidth="2.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    ...defaultToastOptions,
    ...options
  })
}

toast.clear = () => {
  rToast.dismiss()
}

export default toast
