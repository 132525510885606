import { iconTime } from '@/assets'
import { TIcon } from '@/components/tmd'
import rampStore from '@/stores/rampStore'
import { set } from 'date-fns'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

interface IProps {
  start: boolean
  reloadFun?: (amount: string) => void
  amount: string
}
const Countdown = ({ start, reloadFun, amount }: IProps) => {
  const [seconds, setSeconds] = useState<number>(15)

  useEffect(() => {
    if (start) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1
          } else {
            reloadFun && reloadFun(amount)
            return 15
          }
        })
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [start, amount, reloadFun, rampStore.selectedTab])

  return (
    <div className="mt-2 flex items-center justify-end gap-2 text-t3">
      <TIcon name="tg_wallet_time" className="size-4" />
      <div className="w-[40px] text-xs">
        {t('tg_wallet_ramp.trade_tips2', { seconds: seconds })}
      </div>
    </div>
  )
}

export default Countdown
