import { useEffect, useState } from 'react'
import tokenStore from '..'
import { reaction } from 'mobx'

const useTokenStore = () => {
  const [stableTokens, setStableTokens] = useState(
    () => tokenStore.stableTokens
  )
  const [tokenList, setTokenList] = useState(() => tokenStore.tokenList)
  const [isLoading, setIsLoading] = useState(() => tokenStore.isLoading)
  const [whiteTokens, setWhiteTokens] = useState(() => tokenStore.whiteTokens)
  const [customTokens, setCustomTokens] = useState(
    () => tokenStore.customTokens
  )

  useEffect(() => {
    const stableTokensReaction = reaction(
      () => tokenStore.stableTokens,
      (v) => setStableTokens(v)
    )
    const tokenListReaction = reaction(
      () => tokenStore.tokenList,
      (v) => setTokenList(v)
    )
    const loadingReaction = reaction(
      () => tokenStore.isLoading,
      (v) => setIsLoading(v)
    )
    const whiteTokensReaction = reaction(
      () => tokenStore.whiteTokens,
      (v) => setWhiteTokens(v)
    )
    const customTokensReaction = reaction(
      () => tokenStore.customTokens,
      (v) => setCustomTokens(v)
    )

    return () => {
      stableTokensReaction()
      tokenListReaction()
      loadingReaction()
      whiteTokensReaction()
      customTokensReaction()
    }
  }, [])

  return {
    stableTokens,
    tokenList,
    whiteTokens,
    customTokens,
    isLoading
  }
}

export default useTokenStore
