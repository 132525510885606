import axios from 'axios'

axios.interceptors.response.use((response) => {
  return response
})

export const apiInstance = axios.create({
  baseURL:
    'https://orbital-dimensional-season.tron-mainnet.quiknode.pro/d14878573fe23f8f40621a303ee2eaa3c812ba1c'
})

/**
 * all triggersmartcontract
 */
export const trc20Triggersmartcontract = async (params: any) => {
  return await apiInstance.post('/wallet/triggersmartcontract', params)
}
