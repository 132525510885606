import { Button } from 'components/Button'
import { tokenSwapSvg, logoSvg } from 'assets'
import { Popup, SafeArea } from 'antd-mobile'
import Outtime from '@/assets/imgs/oauth/outtime.svg'
import { useWebApp } from '@vkruglikov/react-telegram-web-app'
import useApp from '@/hooks/oauth/useApp'
import useSdk from '@/hooks/oauth/useSdk'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import BaseAvatar from 'components/BaseAvatar'
import useApprove from '@/hooks/oauth/useApprove'
import { loggerA } from '@/utils/sentry/logger'
import isError from '@/utils/sentry/logger/isError'
import { TButton } from '@/components/tmd'
import { Link } from 'react-router-dom'
import { RequestAccounts as ApprovePage } from '@tomo-wallet/uikit'
import useTheme from '@/stores/userStore/hooks/useTheme'

const POLICY_URL =
  'https://telegram.org/tos/bot-developers#7-2-connecting-wallets'

export default function RequestAccounts() {
  const {
    handleApprove,
    isPending,
    isSuccess,
    handleTonProofApprove,
    handleSdkLogin
  } = useApprove()
  const webApp = useWebApp()
  const { actions, webAppReject } = useApp()
  const { getPayload } = useSdk()
  const [visible, setVisible] = useState(actions.platform !== 'web')

  const [status, setStatus] = useState<'normal' | 'loading' | 'success'>(
    'normal'
  )

  const { theme } = useTheme()

  const { data: transData } = useQuery({
    queryKey: ['request-account'],
    queryFn: async () => {
      return await getPayload()
    }
  })

  const transfer = useMemo(() => {
    const metaData = transData?.data?.options?.metaData || {}
    if ((transData?.data?.params || []).length) {
      const data = transData?.data?.params[0] || {}
      return { ...data, ...metaData }
    }
    return { ...metaData }
  }, [transData?.data?.options?.metaData, transData?.data?.params])

  const isTonProof = useMemo(
    () => transfer.tonProof && transfer.domain,
    [transfer.tonProof, transfer.domain]
  )

  const handleLogin = async () => {
    try {
      const isNew = !!transfer?.isNew
      if (isTonProof) {
        const params = {
          payload: transfer?.tonProof,
          domain: transfer?.domain,
          workChain: transfer?.workChain ?? 0
        }
        handleTonProofApprove(params)
      } else {
        isNew ? handleApprove() : handleSdkLogin()
      }
    } catch (error: any) {
      if (isError(error)) {
        loggerA.error('Login', 'login err', error)
      }
    }
  }

  useEffect(() => {
    if (isPending) {
      setStatus('loading')
    } else if (isSuccess) {
      setStatus('success')
      webApp?.close()
    } else {
      setStatus('normal')
    }
  }, [isPending, isSuccess, webApp])

  const goToTomoWallet = () => {
    const walletLink = import.meta.env.VITE_BOT_LINK
    webApp?.openTelegramLink(walletLink)
    webApp?.close()
  }

  const openPolicy = () => {
    webApp?.openLink(POLICY_URL)
  }

  const handleIgnore = () => {
    setVisible(false)
  }

  const showIgnore = useMemo(() => {
    const targetDate = new Date('2025-02-01')
    const now = new Date()
    return now < targetDate
  }, [])

  return (
    <>
      <ApprovePage
        approve={handleLogin}
        reject={() => webAppReject(false)}
        approveBtnStatus={status}
        metaData={{
          dappName: transfer?.name || 'Dapp',
          icon: transfer?.icon,
          url: transfer?.url
        }}
        theme={theme}
      />
      <Popup
        closeIcon={null}
        visible={visible}
        className={`keyboard_foot`}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px'
        }}
        bodyClassName="bg-bg1"
      >
        <div className="flex flex-col items-center gap-4 p-5">
          <img src={Outtime} className="size-32" />
          <p className="text-t1">
            Due to{' '}
            <span
              className="decoration-t1 underline decoration-solid underline-offset-auto"
              onClick={openPolicy}
            >
              Telegram’s updated policy
            </span>
            , starting February 1, 2025, Telegram Mini Apps will only connect to
            Tomo Wallet via TON Connect, exclusively for TON Chain.
          </p>
          <p className="text-t1">
            Tomo Wallet remains fully functional and can still be accessed via
            its Mini App to manage your assets across multiple chains. Thank you
            for your continued support!
          </p>
          <div className="bottom-0 w-full">
            <TButton size="large" block onClick={goToTomoWallet}>
              <span className="text-base font-medium text-white">
                Go to Tomo Wallet
              </span>
            </TButton>
          </div>
          {showIgnore && (
            <span className="text-red text-base" onClick={handleIgnore}>
              Ignore now
            </span>
          )}
          <SafeArea position="bottom" className="flex-none" />
        </div>
      </Popup>
    </>
  )
}
