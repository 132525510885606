import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { base } from '@wagmi/core/chains'

const icon = chainsSvgs.baseSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...base,
    rpcUrls: {
      default: {
        http: [
          'https://quaint-compatible-season.base-mainnet.quiknode.pro/c01230e5c0faaf281d38d343cf487b7f54903c21/',
          'https://base-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO',
          'https://rpc.ankr.com/base/c2487bd8fe1c09aca100f8c32f847cadb5632bd92c1a9baee1107a81de3c1dfc'
        ]
      }
    }
  },
  id: 8453,
  type: 'EVM',
  name: base.name,
  icon: icon,
  networkType
}

export default chainInfo
