/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  createOnrampOrder,
  createOrder,
  getOnrampAccountIdList
} from '@/api/ramp'
import {
  AccountIds,
  HistoryItem,
  QuoteSide,
  SupportPayWay
} from '@/api/ramp/type'
import {
  alchemyPay,
  applePay,
  applePayDark,
  mastercardSvg,
  visaSvg
} from '@/assets'
import Tip from '@/components/Tip'
import { TButton, TContainer, TIcon } from '@/components/tmd'
import useLoginInfo from '@/hooks/useLoginInfo'
import { onRampChain } from '@/proviers/web3Provider/chains'
import { Routers } from '@/router'
import rampStore from '@/stores/rampStore'
import useTheme from '@/stores/userStore/hooks/useTheme'
import { useMutation } from '@tanstack/react-query'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { Radio } from 'antd-mobile'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from '@/components/tmd/toast/Toast'
import { Toast } from '@/components/tmd'
import { getCookieQuery } from './utils'
import useRampStore from '@/stores/rampStore/hooks/useRampStore'
import useAsyncEffect from 'ahooks/lib/useAsyncEffect'
import IText from '@/components/IText'
import Power from './components/Power'
import { t } from 'i18next'

const PaymentMethod = [
  {
    type: 'creditCard',
    lable: <IText value="tg_wallet_ramp_choose.choose_item1" />, //'Credit Card',
    payWayCode: SupportPayWay.CREDITCARD,
    icon: [visaSvg, mastercardSvg]
  },
  {
    type: 'applePay',
    lable: <IText value="tg_wallet_ramp_choose.choose_item2" />, //'Apple Pay',
    payWayCode: SupportPayWay.APPLEPAY,
    icon: [applePay]
  }
  // {
  //   type: 'googlePay',
  //   lable: 'Google Pay',
  //   payWayCode: SupportPayWay.GOOGLEPAY,
  //   icon: [googlePay]
  // }
]

export const ChoosePaymentMethod: React.FC = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const { buyQuotes } = useRampStore()
  const type = rampStore.selectedTab
  const [disabled, setDisabled] = useState<boolean>(false)
  const [amountError, setAmountError] = useState<string>('')
  // const [loading, setLoading] = useState(false)
  const [backShow, setBackShow] = useState(true)

  const getAddressByToken = useLoginInfo().getAddressByToken
  const clearAmount = rampStore.clearAmount
  // const setHistoryList = rampStore.setHistoryList
  // const selectCurrency = rampStore.buySelectedCurrency
  const [seletedPaymentMethod, setSelectedPaymentMethod] = useState<
    SupportPayWay | undefined
  >(undefined)
  const [accountIds, setIds] = useState<AccountIds[] | null>(null)

  const loadData = async () => {
    const list = await getOnrampAccountIdList()
    setIds(list)
  }

  useEffect(() => {
    loadData()
    const time = setInterval(() => {
      loadData()
    }, 10000)
    return () => {
      clearInterval(time)
    }
  }, [])

  const validateAmount = useCallback(() => {
    const payWayObj = buyQuotes.find((i) => i.code === seletedPaymentMethod)
    if (!payWayObj) return
    const minBuy = Number(
      rampStore.buySelectedCurrency?.cryptoDetail?.minBuySize
    )
    const maxBuy = Number(
      rampStore.buySelectedCurrency?.cryptoDetail?.maxBuySize
    )
    if (new BigNumber(rampStore.buyAmount).gt(maxBuy)) {
      setAmountError(
        t('tg_wallet_ramp_choose.choose_toast1', {
          maximum: maxBuy
        })
      )
      return false
    }
    if (new BigNumber(rampStore.buyAmount).lt(minBuy)) {
      setAmountError(
        t('tg_wallet_ramp_choose.choose_toast2', {
          minimum: minBuy
        })
      )
      return false
    }
    setAmountError('')
  }, [seletedPaymentMethod])

  useEffect(() => {
    if (!seletedPaymentMethod) return
    validateAmount()
  }, [seletedPaymentMethod, validateAmount])

  const { mutate, isPending } = useMutation({
    mutationFn: createOnrampOrder,
    onSuccess: (data: any) => {
      Toast.clear()
      if (!data?.data) {
        toast.error(data?.msg || t('tg_wallet_ramp_choose.choose_toast3'))
        return
      }
      clearAmount()
      if (data.data.payUrl) {
        if (seletedPaymentMethod === SupportPayWay.CREDITCARD) {
          setBackShow(false)
        }
        window.location.href = data.data.payUrl
      } else {
        toast(t('tg_wallet_ramp_choose.choose_toast4'))
      }
    },
    onError: (e) => {
      Toast.clear()
      toast.error(e.message || t('tg_wallet_ramp_choose.choose_toast3'))
      setDisabled(false)
    }
  })

  const onProceed = async () => {
    if (!seletedPaymentMethod) {
      return
    }
    rampStore.setSelectPayWayCode(seletedPaymentMethod)
    if (seletedPaymentMethod === SupportPayWay.APPLEPAY) {
      Toast.show({
        icon: 'loading',
        content: '',
        duration: 0,
        maskClickable: false
      })

      let cookieQuery = {
        cookie: '',
        channalCookie: '',
        clientIp: ''
      }
      try {
        cookieQuery = await getCookieQuery()
      } catch (e) {
        Toast.clear()
        toast.error(t('tg_wallet_ramp_choose.choose_toast5'))
        return
      }

      if (!cookieQuery.channalCookie || !cookieQuery.cookie) {
        Toast.clear()
        toast.error(t('tg_wallet_ramp_choose.choose_toast6'))
        return
      }
      const extendParams = {
        cookie: cookieQuery.cookie,
        channelCookie: cookieQuery.channalCookie
      }
      const clientIp = cookieQuery.clientIp

      // const jumpURL = import.meta.env.VITE_BOT_LINK + `?startapp=RAMP_${id}`
      // const redirectUrl = `https://tomo.inc/union?url=${encodeURIComponent(
      //   jumpURL
      // )}`
      const params = {
        clientOid: '',
        memo: '',
        orderTime: '',
        userPaymentId: '0',
        address: getAddressByToken(rampStore.selectedToken) || '',
        code: SupportPayWay.APPLEPAY,
        depositType: '4',
        fiat: rampStore.buySelectedCurrency.symbol,
        network: onRampChain[rampStore.selectedToken?.chainId].chain,
        side: '0',
        size: rampStore.buyAmount,
        amount: buyQuotes[0].size,
        symbol: rampStore.selectedToken.symbol,
        channelCookie: extendParams.channelCookie,
        cookie: extendParams.cookie,
        clientIp
      }
      mutate(params)
      return
    }
    if (accountIds?.length) {
      try {
        const lastNum = accountIds[0].cardNumber.split('/')[1]
        const billFlag = accountIds[0].billAddress
        if (lastNum && billFlag) {
          navigate(
            Routers.ramp.confirmPayment(lastNum, accountIds[0].id.toString())
          )
          return
        }
      } catch (e) {
        toast.error('get account error')
        return
      }
    }
    navigate(Routers.ramp.cardDetails)
  }
  return (
    <>
      {backShow && <BackButton onClick={() => navigate(-1)} />}
      <TContainer className="flex flex-1 flex-col">
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col">
            <div className="flex h-[52px] items-center">
              <span className="text-xl font-semibold text-t1">
                <IText value="tg_wallet_ramp_choose.choose_title" />
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <Radio.Group
                value={seletedPaymentMethod}
                onChange={(val) => {
                  setSelectedPaymentMethod(val as SupportPayWay)
                }}
              >
                {PaymentMethod.map((item) => (
                  <Radio
                    style={{
                      '--icon-size': '16px',
                      '--gap': '0px'
                    }}
                    icon={(checked) => <></>}
                    disabled={
                      !buyQuotes.map((i) => i.code).includes(item.payWayCode)
                    }
                    key={item.payWayCode}
                    value={item.payWayCode}
                    className={classNames(
                      'p-4 rounded-lg bg-bg3',
                      seletedPaymentMethod === item.payWayCode &&
                        'border border-primary'
                    )}
                  >
                    <div
                      className={classNames(
                        'flex w-full items-center justify-between',
                        !buyQuotes
                          .map((i) => i.code)
                          .includes(item.payWayCode) && ' opacity-50 '
                      )}
                    >
                      <span
                        className={classNames(
                          'text-base font-medium text-t1',
                          !buyQuotes
                            .map((i) => i.code)
                            .includes(item.payWayCode) && ' !text-t3'
                        )}
                      >
                        {item.lable}
                      </span>
                      <div className="flex gap-2">
                        {item.type === 'creditCard' ? (
                          item.icon.map((icon, index) => (
                            <img className="h-5" src={icon} key={index} />
                          ))
                        ) : theme === 'light' ? (
                          <img className="h-5" src={applePay} />
                        ) : (
                          <img className="h-5" src={applePayDark} />
                        )}
                      </div>
                    </div>
                    {!buyQuotes.map((i) => i.code).includes(item.payWayCode) ? (
                      <div className="mt-[4px]">
                        <TIcon
                          name="tg_wallet_present-linearly"
                          className="text-red2"
                          fontSize="12"
                        />
                        <span className="ml-px text-xs text-t3">
                          This payment method is not supported for{' '}
                          {rampStore.buySelectedCurrency.symbol || ''}.
                        </span>
                      </div>
                    ) : null}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
          <div className="flex flex-col">
            {!!amountError && <Tip content={amountError} />}
            <TButton
              size="large"
              onClick={onProceed}
              color="primary"
              className="text-base font-medium"
              disabled={
                !seletedPaymentMethod ||
                disabled ||
                !!amountError ||
                !accountIds
              }
              // loading={isPending}
            >
              <IText value="tg_wallet_ramp_choose.choose_btn" />
            </TButton>
            <Power />
          </div>
        </div>
      </TContainer>
    </>
  )
}
